<template>
<b-card no-body class="card-company-table">
    <table class="table b-table" v-if="questions">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Sıra</th>
                <th scope="col">Soru Resmi</th>
                <th scope="col">Çözüm Videosu</th>
                <th scope="col">İşlemler</th>
            </tr>
        </thead>
        <draggable v-model="questions" tag="tbody" handle=".handle">
            <tr v-for="(item,index) in questions" :key="item.id">
                <td>
                    <feather-icon icon="MoveIcon" class="handle" />
                </td>
                <td><span class="font-weight-bolder mb-12">#{{index+1 }}</span></td>
                <td> <img class="font-weight-bolder mb-12" style="max-height:150px" :src="item.questionFile.url" /></td>
                <td> <span class="font-weight-bolder mb-12">{{item.solutionFile.url}}</span></td>
                <td>
                    <feather-icon icon="EditIcon" @click="editQuestion(item)" /> &nbsp;&nbsp;
                    <feather-icon icon="DeleteIcon" @click="deleteQuestion(item)" />
                </td>
            </tr>
        </draggable>
    </table>
    <!--Add Test Question --->
    <b-modal title="Soru Düzenle" ok-title="Düzenle" ok-only cancel-variant="outline-secondary" no-close-on-backdrop v-model="editQuestionPanel" @ok="editTestQuestion">
        <b-form>
            <b-form-group label="Var Olan Resim Url" label-for="name">
                <b-form-input name="firstName" id="name" type="text" readonly v-model="editQuestionRequest.questionUrl" />
            </b-form-group>
            <b-form-group label="Soru Resmi ?" label-for="name">
                <b-form-file ref="file-input" v-model="editQuestionRequest.file" />
            </b-form-group>
            <b-form-group label="Çözüm Videosu ?" label-for="name">
                <b-form-input name="firstName" id="name" type="text" v-model="editQuestionRequest.solutionUrl" />
            </b-form-group>
            <b-form-group label="Başlama Süresi ?" label-for="name">
                <cleave id="time" v-model="editQuestionRequest.startTime" class="form-control" :raw="false" :options="time" placeholder="sa:dk:sn" />
            </b-form-group>
        </b-form>
    </b-modal>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    BFormFile
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import Cleave from 'vue-cleave-component'
export default {
    components: {
        Cleave,
        BFormFile,
        ToastificationContent,
        draggable,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BFormDatepicker,
        vSelect
    },
    props: ["videoId", "saveEvent"],
    data() {
        return {
            time: {
                time: true,
                timePattern: ['h', 'm', 's'],
            },
            questions: null,
            editQuestionPanel: false,
            editQuestionRequest: {
                questionId: 0,
                questionUrl: '',
                solutionUrl: '',
                startTime: '00:00:00'
            },
        }
    },
    watch: {
        saveEvent() {
            this.getData();
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            var questions = await this.$http.get("Teacher/VideoQuestion/" + this.videoId);
            this.questions = questions.data.data;
        },
        async deleteQuestion(question){
            await this.$http.delete("Teacher/DeleteVideoQuestion/"+question.id);
            this.getData();
        },
        editQuestion(question) {
            this.editQuestionRequest = {
                questionId: question.id,
                questionUrl: question.questionFile.url,
                solutionUrl: question.solutionFile.url,
                startTime: question.startTime
            };
            this.editQuestionPanel = true;
        },
        async editTestQuestion() {
            var formData = new FormData();
            formData.append("questionId", this.editQuestionRequest.questionId);
            formData.append("solutionUrl", this.editQuestionRequest.solutionUrl ?? "");
            formData.append("startTime", this.editQuestionRequest.startTime);
            formData.append("file", this.editQuestionRequest.file);
            await this.$http.post("Teacher/UpdateVideoQuestion", formData);
            this.getData();
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
