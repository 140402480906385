<template>
<b-card no-body class="card-company-table">
    <table class="table b-table" v-if="capture">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Sıra</th>
                <th scope="col">Bölüm Adı</th>
                <th scope="col">Bölüm Başlangıç Süresi</th>
                <th scope="col">Bölüm Bitiş Süresi</th>
                <th scope="col">İşlemler</th>
            </tr>
        </thead>
        <draggable v-model="capture" tag="tbody" handle=".handle">
            <tr v-for="(item,index) in capture" :key="item.id">
                <td>
                    <feather-icon icon="MoveIcon" class="handle" />
                </td>
                <td><span class="font-weight-bolder mb-12">#{{index+1 }}</span></td>
                <td> <span class="font-weight-bolder mb-12">{{item.title }}</span></td>
                <td> <span class="font-weight-bolder mb-12">{{item.startTime}}</span></td>
                <td> <span class="font-weight-bolder mb-12">{{item.endTime}}</span></td>
                <td>
                    <feather-icon icon="EditIcon" @click="editCapture(item)" />
                </td>
            </tr>
        </draggable>
    </table>
    <!--Add Test Question --->
    <b-modal title="Bölüm Düzenle" ok-title="Düzenle" ok-only cancel-variant="outline-secondary" no-close-on-backdrop v-model="editCapturePanel" @ok="editVideoCapture">
        <b-form>
            <b-form-group label="Bölüm Adı ?" label-for="name">
                <b-form-input name="firstName" id="name" type="text" v-model="editCaptureRequest.title" />
            </b-form-group>
            <b-form-group label="Başlama Süresi ?" label-for="name">
                <cleave id="time" v-model="editCaptureRequest.startTime" class="form-control" :raw="false" :options="time" placeholder="sa:dk:sn" />
            </b-form-group>
            <b-form-group label="Başlama Süresi ?" label-for="name">
                <cleave id="time" v-model="editCaptureRequest.endTime" class="form-control" :raw="false" :options="time" placeholder="sa:dk:sn" />
            </b-form-group>
        </b-form>
    </b-modal>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import Cleave from 'vue-cleave-component'
export default {
    components: {
        Cleave,
        ToastificationContent,
        draggable,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BFormDatepicker,
        vSelect
    },
    props: ["videoId", "saveEvent"],
    data() {
        return {
            time: {
                time: true,
                timePattern: ['h', 'm', 's'],
            },
            capture: null,
            editCapturePanel: false,
            editCaptureRequest: {
                captureId: 0,
                title: '',
                startTime: '00:00:00',
                endTime: '00:00:00'
            },
        }
    },
    watch: {
        saveEvent() {
            this.getData();
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            var captures = await this.$http.get("Teacher/VideoCapture/" + this.videoId);
            this.capture = captures.data.data;
        },
        editCapture(capture) {
            this.editCaptureRequest = {
                captureId: capture.id,
                title: capture.title,
                startTime: capture.startTime,
                endTime: capture.endTime
            };
            this.editCapturePanel = true;
        },
        async editVideoCapture() {
            await this.$http.post("Teacher/UpdateVideoCapture", this.editCaptureRequest);
            this.getData();
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
